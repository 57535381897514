// @flow
//Core
import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Button, Chip } from '@material-ui/core';
import classNames from 'classnames';
import get from 'lodash.get';
import formatDate from 'date-format';
//Local
import typography from '../../../../styles/typography.module.scss';
import { Card } from '../../..';
import { itemsStyles } from '../styles';
import JobPreviewImg from '../../../../images/jobPreview.jpg';

const JobsSec = ({
  title,
  subtitle,
  tags,
  items: initialItems,
  loadBy = 8,
  handleCustomLoadMore,
  isLoaded: customIsLoaded,
  selectedTag,
}: {
  title: string,
  subtitle: string,
  tags: Array<Object>,
  items: Array<Object>,
  loadBy?: number,
  handleCustomLoadMore?: Function,
  isLoaded?: boolean,
  selectedTag?: string,
}) => {
  const styles = itemsStyles();

  const [items, setItems] = useState(initialItems);
  const [filterBy, setFilterBy] = useState(null);
  const [loadedCount, setLoadedCount] = useState(loadBy);
  const isLoaded =
    typeof customIsLoaded === 'boolean'
      ? customIsLoaded
      : loadedCount >= initialItems.length;

  const handleTagClick = (id) => {
    setFilterBy(id);
  };

  const handleLoadMoreClick = () => {
    setLoadedCount(
      loadedCount + loadBy <= initialItems.length
        ? loadedCount + loadBy
        : initialItems.length
    );
  };

  const filterItemsByTag = (data) =>
    data.filter((item) =>
      filterBy ? get(item, 'parent.id', []) === filterBy : initialItems
    );

  const filterLoadedItems = (data) => data.slice(0, loadedCount);

  useEffect(() => {
    setItems(
      handleCustomLoadMore
        ? filterItemsByTag(initialItems)
        : filterLoadedItems(filterItemsByTag(initialItems))
    );
  }, [initialItems, filterBy, loadedCount]);

  useEffect(() => {
    const tag = tags.find((t) => t.name === selectedTag);

    if (!tag) {
      setFilterBy(null);
    } else if (tag.id !== filterBy) {
      setFilterBy(tag.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, tags]);

  const tagsJSX = [
    {
      name: 'すべての記事',
      id: null,
    },
    ...tags,
  ].map((item, index) => (
    <Chip
      label={item.name}
      onClick={() => handleTagClick(item.id)}
      key={index}
      clickable
      color="secondary"
      className={classNames(styles.tag, {
        [styles.activeTag]: item.id === filterBy,
      })}
    />
  ));

  const itemsJSX = items.map((item, index) => (
    <Grid xs={12} md={6} key={index} item>
      <Card
        img={JobPreviewImg}
        title={get(item, 'title')}
        tags={[item.parent.name]}
        link={`/media_center/work_life/recruitment/job/${get(
          item,
          'greenhouseId'
        )}`}
        variant="primary"
        theme="light"
        size="md"
        className={styles.card}
        date={formatDate('yyyy/MM/dd', new Date(get(item, 'created_at')))}
      />
    </Grid>
  ));

  return (
    <section className={styles.section}>
      <Typography className={styles.title} variant="h2">
        {title}
      </Typography>
      <Typography
        className={classNames(
          styles.subtitle,
          typography.withDividerLeftPurple
        )}
        variant="body1"
      >
        {subtitle}
      </Typography>
      <Box className={styles.tags}>{tagsJSX}</Box>
      <Grid
        container
        spacing={6}
        className={classNames({
          [styles.itemsWithBg]: !isLoaded,
        })}
      >
        {itemsJSX}
      </Grid>
      {!isLoaded && (
        <Box textAlign="center">
          <Button
            className={styles.loadMoreBtn}
            variant="outlined"
            color="primary"
            onClick={handleCustomLoadMore || handleLoadMoreClick}
          >
            表示を増やす
          </Button>
        </Box>
      )}
    </section>
  );
};

JobsSec.defaultProps = {
  items: [],
  handleCustomLoadMore: undefined,
  isLoaded: undefined,
  loadBy: 8,
  selectedTag: '',
};

export default JobsSec;
