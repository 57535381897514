// @flow
//Core
import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
//Local
import typography from '../../../../styles/typography.module.scss';
import { previewStyles } from '../styles';

const PreviewSec = ({
  title,
  subtitle,
  primaryText,
  secondaryText,
  primaryImage,
  secondaryImage,
  tetriaryImage1,
  tetriaryImage2,
}: {
  title: string,
  subtitle: string,
  primaryText: string,
  secondaryText: string,
  primaryImage: string,
  secondaryImage: string,
  tetriaryImage1: string,
  tetriaryImage2: string,
}) => {
  const styles = previewStyles();

  return (
    <section className={styles.previewSec}>
      <Container maxWidth="md">
        <Typography
          component="h1"
          variant="h2"
          className={classNames(typography.withDividerPurple, styles.title)}
        >
          {title}
        </Typography>
        <Typography className={styles.subtitle} variant="body2">
          {subtitle}
        </Typography>
      </Container>
      <Box className={styles.gridContainer}>
        <Box className={styles.primaryImage}>
          <img src={primaryImage} alt="" className={styles.img} />
        </Box>
        <Box className={styles.secondaryImage}>
          <img src={secondaryImage} alt="" className={styles.img} />
        </Box>
        <Box className={styles.tertriaryImage1}>
          <img src={tetriaryImage1} alt="" className={styles.img} />
        </Box>
        <Box className={styles.tertriaryImage2}>
          <img src={tetriaryImage2} alt="" className={styles.img} />
        </Box>
        <Box className={styles.primaryText}>
          <Typography variant="h4" color="secondary" className={styles.text}>
            {primaryText}
          </Typography>
        </Box>
        <Box className={styles.secondaryText}>
          <Typography variant="h4" color="primary" className={styles.text}>
            {secondaryText}
          </Typography>
        </Box>
      </Box>
    </section>
  );
};

export default PreviewSec;
