// @flow
//Core
import React from 'react';
import get from 'lodash.get';
import { Container } from '@material-ui/core';
import { graphql } from 'gatsby';
//Local
import { Layout } from '../../../../components';
import {
  PreviewSec,
  ItemsGraphSec,
} from '../../../../components/mediaCenterSections/recruitment';
import { SITE_METADATA } from '../../../../constants';

export const query = graphql`
  {
    contentfulPageMediaCenterRecruitment(
      contentful_id: { ne: "5mCqMKbm5Zmj4OU6uhg9np" }
    ) {
      pageSubtitle
      pageTitle
      firstSectionPrimaryText
      firstSectionSecondaryText
      firstSectionPrimaryImage {
        file {
          url
        }
      }
      firstSectionSecondaryaryImage {
        file {
          url
        }
      }
      firstSectionTertiaryImage1 {
        file {
          url
        }
      }
      firstSectionTertiaryImage2 {
        file {
          url
        }
      }
      secondSectionSubtitle
      secondSectionTitle
      thirdSectionSubtitle
      thirdSectionTitle
      thirdSectionTags {
        title
        contentful_id
      }
    }
    allContentfulComponentArticle(
      filter: {
        blogSection: { contentful_id: { eq: "1w915a0HhAJPBs9eqSjl19" } }
      }
    ) {
      edges {
        node {
          contentful_id
          date
          blogSection {
            urlKey
          }
          previewImage {
            file {
              url
            }
          }
          title
          tags {
            title
            contentful_id
          }
        }
      }
    }
    site {
      siteMetadata {
        ogImage
        title
        twitterImage
        siteUrl
      }
    }
  }
`;

const Recruitment = ({
  data,
  location,
}: {
  data: Object,
  location: Object,
}) => {
  const tag = decodeURIComponent(get(location, 'hash', '').replace('#', ''));

  return (
    <Layout
      isMediaCenter
      SEOProps={{
        title: SITE_METADATA.recruit.title,
        description: SITE_METADATA.recruit.description,
      }}
    >
      <PreviewSec
        title={get(data, 'contentfulPageMediaCenterRecruitment.pageTitle')}
        subtitle={get(
          data,
          'contentfulPageMediaCenterRecruitment.pageSubtitle'
        )}
        primaryText={get(
          data,
          'contentfulPageMediaCenterRecruitment.firstSectionPrimaryText'
        )}
        secondaryText={get(
          data,
          'contentfulPageMediaCenterRecruitment.firstSectionSecondaryText'
        )}
        primaryImage={get(
          data,
          'contentfulPageMediaCenterRecruitment.firstSectionPrimaryImage.file.url'
        )}
        secondaryImage={get(
          data,
          'contentfulPageMediaCenterRecruitment.firstSectionSecondaryaryImage.file.url'
        )}
        tetriaryImage1={get(
          data,
          'contentfulPageMediaCenterRecruitment.firstSectionTertiaryImage1.file.url'
        )}
        tetriaryImage2={get(
          data,
          'contentfulPageMediaCenterRecruitment.firstSectionTertiaryImage2.file.url'
        )}
      />
      <Container maxWidth="md">
        <ItemsGraphSec
          title={get(
            data,
            'contentfulPageMediaCenterRecruitment.thirdSectionTitle'
          )}
          subtitle={get(
            data,
            'contentfulPageMediaCenterRecruitment.thirdSectionSubtitle'
          )}
          tags={
            Array.isArray(
              get(data, 'contentfulPageMediaCenterRecruitment.thirdSectionTags')
            )
              ? get(
                  data,
                  'contentfulPageMediaCenterRecruitment.thirdSectionTags'
                )
              : []
          }
          items={get(data, 'allContentfulComponentArticle.edges', [])}
          selectedTag={tag}
        />
      </Container>
    </Layout>
  );
};

export default Recruitment;
